
import { defineComponent } from 'vue';

import AppButton from '@/components/stateless/AppButton.vue';

export default defineComponent({
  name: 'Buttons',

  components: { AppButton },

});
