<template>
  <div class="text-primary text-3xl text-center py-7 border-b border-grey-fp-30 ">Buttons</div>
  <div class="h-full">
    <div class="flex items-center py-20">
      <p class="mb-10 text-grey-fp-70 text-2xl mt-10 mr-20">Sizes:</p>
      <div class="flex items-center py-10">
        <AppButton type="primary" size="large" class="mr-10 px-30"> Primary </AppButton>
        <AppButton type="primary" size="medium" class="mr-10 px-30"> Primary </AppButton>
        <AppButton type="primary" size="small" class="mr-10 px-20"> Primary </AppButton>
        <AppButton type="primary" size="mini" class="mr-10 px-20"> Primary </AppButton>
      </div>
    </div>

    <div class="flex items-center py-20">
      <p class="mb-10 text-grey-fp-70 text-2xl mt-10 mr-20">Types:</p>
      <div class="flex items-center py-10">
        <AppButton type="primary" class="mr-10 px-30"> Success </AppButton>
        <AppButton type="error" class="mr-10 px-30"> Error </AppButton>
        <AppButton type="success" class="mr-10 px-20"> Primary </AppButton>
        <AppButton type="warning" class="mr-10 px-20"> Warning </AppButton>
        <AppButton class="mr-10 px-20"> Default </AppButton>
      </div>
    </div>

    <div class="flex items-center py-20">
      <p class="mb-10 text-grey-fp-70 text-2xl mt-10 mr-20">Disabled:</p>
      <div class="flex items-center py-10">
        <AppButton type="primary" disabled size="large" class="mr-10 px-30"> Primary </AppButton>
        <AppButton type="error" disabled size="medium" class="mr-10 px-30"> Error </AppButton>
        <AppButton type="success" disabled size="small" class="mr-10 px-20"> Success </AppButton>
        <AppButton type="warning" disabled size="mini" class="mr-10 px-20"> Warning </AppButton>
        <AppButton class="mr-10 px-20" disabled size="mini"> Default </AppButton>
      </div>
    </div>

    <div class="flex items-center py-20">
      <p class="mb-10 text-grey-fp-70 text-2xl mt-10 mr-20">Plain:</p>
      <div class="flex items-center py-10">
        <AppButton type="primary" plain class="mr-10 px-30"> Primary </AppButton>
        <AppButton type="error" plain class="mr-10 px-30"> Error </AppButton>
        <AppButton type="success" icon="plus" icon-size="12" plain class="mr-10 px-20"> Success </AppButton>
        <AppButton type="secondary" class="mr-10 px-20" plain> Secondary </AppButton>
        <AppButton type="warning" class="mr-10 px-20" plain> Warning </AppButton>
        <AppButton class="mr-10 px-20" plain> Default </AppButton>
      </div>
    </div>

    <div class="flex items-center py-20">
      <p class="mb-10 text-grey-fp-70 text-2xl mt-10 mr-20">With Icon:</p>
      <div class="flex items-center py-10">
        <AppButton type="primary" class="mr-10 px-30" icon-size="16" icon="plus"> Primary </AppButton>
        <AppButton type="error" class="mr-10 px-30" icon-size="16" icon="calendar"> Error </AppButton>
        <AppButton type="success" class="mr-10 px-20" icon-size="16" icon="notifications" plain> Success </AppButton>
        <AppButton type="secondary" class="mr-10 px-20" icon-size="14" icon="plus"> Secondary </AppButton>
        <AppButton class="mr-10 px-20" icon-size="16" icon="user"> Default </AppButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import AppButton from '@/components/stateless/AppButton.vue';

export default defineComponent({
  name: 'Buttons',

  components: { AppButton },

});
</script>